import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import GetStartedSteps from "../components/getStartedSteps"

class IndexPage extends React.Component {
  render() {
    /* if (typeof window !== `undefined`) {
      const parallax = require('../script/parallax');
    } */

    return (
      <Layout>
        <SEO title="Create and manage MTG events" />
        <div id="hero-push">
          <div id="hero-container">
            <div id="hero">
              <Image filename="mtg-event-logo" alt="MTGevent.com logo" />
              <h1>Easily create and manage MTG events</h1>
              <h2>The free Magic: The Gathering event manager</h2>
              <div className="hero-button-container">
                <a href="https://app.mtgevent.com/signup" className="button primary">Get started</a>
              </div>
              {/* <div className="parallax parallax-1"></div>
              <div className="parallax parallax-2"></div>
              <div className="parallax parallax-6"></div>
              <div className="parallax parallax-10"></div>
              <div className="parallax parallax-8"></div>
              <div className="parallax parallax-9"></div>
              <div className="parallax parallax-3"></div>
              <div className="parallax parallax-4"></div>
              <div className="parallax parallax-5"></div>
              <div className="parallax parallax-7"></div>*/}

            </div>
          </div>
        </div>
        <div className="features">
          <div className="feature">
            <div className="feature-container container">
              <div className="left img">
                <Image filename="devices" alt="Create and manage Magic tournaments" />
              </div>
              <div className="right text">
                <h2>Create and manage events</h2>
                <p>With MTGevent.com you can easily host tournaments for your playgroup or community! The free tool lets you set up tournaments, invite participants and manage all of the pairings, results calculations and tiebreakers for you!</p>
                <p>All that's left for you to do is assemble your deck and beat your opponents.</p>
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-container container">
              <div className="left text">
                <h2>Multiple formats and tournament types</h2>
                <p>MTG Event is flexible enough to let you play your favorite format in the tournament style that suits you and your group. Whether you enjoy a Swiss style Draft or a Single-elimination constructed tournament, we've got you covered.</p>
              </div>
              <div className="right img">
                <Image filename="single-elimination-mockup" alt="Keep track of your MTG stats" />
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-container container">
              <div className="left img">
                <Image filename="mobile_screens" alt="Create and manage Magic tournaments" />
              </div>
              <div className="right text">
                <h2>User-friendly experience</h2>
                <p>You should be able to focus more on your next game of Magic and less on understanding complex interfaces. MTGevent.com is intuitive and easy to use with a user-friendly interface, both on mobile and desktop.</p>
              </div>
            </div>
          </div>
        </div>
        <GetStartedSteps />
      </Layout>
    )
  }
};

export default IndexPage
